<template>
  <div class="application-list flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>充值记录</span>
      <span></span>
    </header>
    <main class="page-body flex-fill">
      <div class="card" v-for="(item, index) in list" :key="index">
        <header class="card-header">编号：{{ item.sCode }}</header>
        <div class="card-body">
          <div class="prop">
            <span class="label">类型：</span>
            <span>{{ item.iType === 1 ? '充值' : '提现' }}</span>
          </div>
          <div class="prop">
            <span class="label">金额：</span>
            <span>{{ item.fAmount }}</span>
          </div>
          <div class="prop">
            <span class="label">备注：</span>
            <span>{{ item.sComment }}</span>
          </div>
        </div>
        <footer class="card-footer flexbox space-bt">
          <div>日期：{{ item.dCreateTime | time }}</div>
          <div>
            状态：<span class="tag" :class="{ done: item.iStatus === 1 }">{{
              item.iStatus === 1 ? '完成' : '处理中'
            }}</span>
          </div>
        </footer>
      </div>
    </main>
  </div>
</template>
<script>
import { getApplicationList } from '@/api/money.js';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getApplicationList({}).then(res => {
        this.list = res.rows;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.card-header {
  padding: rem(20px);
  border-bottom: 1px solid #b8b8b8;
  font-weight: bold;
}
.card-body {
  padding: rem(20px);
}
.card-footer {
  padding: rem(20px);
  border-top: 1px solid #b8b8b8;
}
.prop {
  line-height: 2;
  .label {
    font-weight: bold;
  }
}
.card {
  background-color: #fff;
  border-radius: rem(15px);
  box-shadow: 0px 2px 10px 0 rgba(14, 48, 53, 0.1);
  & + .card {
    margin-top: rem(20px);
  }
}
.tag {
  padding-left: rem(40px);
  color: $theme-color;
  position: relative;
  &.done {
    color: darkcyan;
  }
  &::before {
    content: '';
    display: inline-block;
    width: rem(30px);
    height: rem(30px);
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: currentColor;
    margin-right: rem(10px);
  }
}
</style>
